import { clone } from "lodash";
import moment from "moment";
import { RouterKakao } from "./types/kakao";

/* -------------------------------- 전화 번호의 포맷 ------------------------------- */
export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber?.length > 0 && /^\d+$/.test(phoneNumber)) {
    const cleanNumber = phoneNumber.replace(/\D/g, "");
    const firstThreeDigits = cleanNumber.slice(0, 3);
    const remainingDigits = cleanNumber.slice(3);
    const chunks = remainingDigits.match(/.{1,4}/g);
    const formattedNumber = firstThreeDigits + "-" + (chunks ? chunks.join("-") : "");

    return formattedNumber;
  } else {
    return phoneNumber;
  }
};

/* ----------------------------- url에서 파라미터 가져오기 ---------------------------- */
export const getUrlParams = (id: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let paramValue = urlParams.get(id) || "";
  return paramValue;
};

/* ----------------------------------- 절사 ----------------------------------- */
export function roundToNearest100(amt: number) {
  return Math.round(amt / 1000) * 1000;
}

/* ------------------------------- Delay 시간 포맷 ------------------------------ */
export function formatDelay(value: string) {
  const momentObj = moment(value)?.local();
  const now = moment();
  const timeDifference = Math.abs(now.diff(momentObj));
  const duration = moment.duration(timeDifference);

  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  let result = '';
  if (months > 0) result += `${months}개월 `;
  if (days > 0) result += `${days}일 `;
  if (hours > 0) result += `${hours}시간 `;
  if (minutes > 0) result += `${minutes}분 `;

  return result.trim();
}

/* ---------------------------------- 기간 포맷 --------------------------------- */
export function formatDateRange(input: string) {
  // Split the input string into year and month
  if (input) {
    const [year, month]: any = input?.split(".");

    // Construct the start and end dates
    const startDate = new Date(year, month - 1, 1); // Month is 0-indexed in JavaScript Date objects
    const endDate = new Date(year, month, 0); // Get the last day of the month

    // Format the dates as yyyy.mm.dd
    const formattedStartDate = startDate.toISOString()?.slice(0, 10);
    const formattedEndDate = endDate.toISOString()?.slice(0, 10);

    // Return the formatted date range
    return `${input}.01 - ${input}.${endDate.getDate()}`;
  }
}

/* -------------------------------- 전화 번호의 포맷 ------------------------------- */
export const formatPhoneNumberOnChange = (phone: string) => {
  const numericValue = phone?.replace(/\D/g, "");
  let hyphenPosition = 3;

  const formattedValue = numericValue
    ?.split("")
    ?.map((char: any, index: any) => {
      if (index === hyphenPosition) {
        hyphenPosition += 4;
        return `-${char}`;
      }
      return char;
    })
    ?.join("");
  return formattedValue;
};

/* -------------------------------- 2개 리스트 삽입 ------------------------------- */
export function mergeArrays(arr1: any, arr2: any) {
  const map = new Map();

  // Add values from arr1 to the map
  arr1.forEach((item: any) => {
    const { month, ...rest } = item;
    if (map.has(month)) {
      map.set(month, { ...map.get(month), ...rest });
    } else {
      map.set(month, { ...rest });
    }
  });

  // Add values from arr2 to the map
  arr2.forEach((item: any) => {
    const { month, ...rest } = item;
    if (map.has(month)) {
      map.set(month, { ...map.get(month), ...rest });
    } else {
      map.set(month, { ...rest });
    }
  });

  // Convert map back to an array
  const result: any = [];
  map.forEach((value, key) => {
    result.push({ month: key, ...value });
  });

  return result;
}

/* ---------------------------------- 시간 포맷 --------------------------------- */
export const combineDateTime = (date: string, time: string) => {
  const normalizedTimeValue = time?.replace("PMZ", "PM");

  // Combine date and time
  const combinedDateTime = normalizedTimeValue
    ? moment(`${date} ${normalizedTimeValue}`, "YYYY.MM.DD hh:mm A")
    : moment(`${date} `, "YYYY.MM.DD hh:mm A");

  // Format the result in the desired format
  const formattedDateTime = combinedDateTime?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  return formattedDateTime;
};

/* ----------------------------- 한국 시간 기준 시간 포맷 ----------------------------- */
export const koreanTimezone = (timestamp: string) => {
  const date = new Date(timestamp);

  // Get individual components
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Custom format
  const customFormat = `${year}. ${month}. ${day} ${period} ${formattedHours
    ?.toString()
    ?.padStart(2, "0")} : ${minutes}`;

  return customFormat;
};

export const getDateFromData = (dateFrom: any) => {
  let paramValue = moment(dateFrom).set("hour", 0).set("minutes", 0).format("YYYY-MM-DDTHH:mm:ssZ");
  return paramValue;
};

export const getDateToData = (dateTo: any) => {
  let paramValue = moment(dateTo).set("hours", 23).set("minutes", 59).format("YYYY-MM-DDTHH:mm:ssZ");
  return paramValue;
};

/* ----------------------------- 현지 시간 기준 시간 포맷 ----------------------------- */
export const convertToLocalTimezone = (date: string | any, isEndOfDay: boolean = false) => {
  if (isEndOfDay) {
    return moment(date)?.endOf("day")?.local()?.format("YYYY-MM-DDTHH:mm:ssZ");
  }
  return moment(date)?.local()?.format("YYYY-MM-DDTHH:mm:ssZ");
};

/* --------------------------------- 파일 다운로드 -------------------------------- */
export function downloadFileLink(url: string, fileName: string) {
  // Use the Fetch API to get the file
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      downloadFileFromBlob(blob, fileName);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
}

/* --------------------------------- 파일 다운로드 -------------------------------- */
export function downloadFileFromBlob(blob: any, fileName: string) {
  try {
    // Create a link element
    const link = document.createElement("a");

    // Create a Blob URL for the file
    const blobUrl = window.URL.createObjectURL(blob);

    // Set the download attribute and href
    link.download = fileName;
    link.href = blobUrl;

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}

export async function wait(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/* ---------------------------------- 소수 확인 --------------------------------- */
export function checkDecimal(value: string, decimalDigits: number = 2) {
  const regexPattern = `^\\d+(\\.\\d{0,${decimalDigits}})?$`;
  return new RegExp(regexPattern).test(value);
}

/* -------------------------------- 전화 번호 확인 -------------------------------- */
export const checkPhoneKorean = (phone: string) => {
  const regex = /^(010|011|016|017|018|019)-[0-9]{4}-[0-9]{4}$/gm;
  return regex.test(phone);
};

export const swapIndex = <T extends any>(arr: T[], index1: number, index2: number) => {
  const newArr = clone(arr);
  if (index1 >= 0 && index1 < newArr.length && index2 >= 0 && index2 < newArr.length) {
    // Sử dụng một biến tạm thời để lưu trữ giá trị tại index1
    var temp = newArr[index1];

    // Gán giá trị tại index1 bằng giá trị tại index2
    newArr[index1] = newArr[index2];

    // Gán giá trị tại index2 bằng giá trị tạm thời
    newArr[index2] = temp;
  }
  return newArr;
};

export const combineDateTimeIncludeTimezone = (date: string | any, time: string | any) => {
  const combinedDateTimeString = `${date?.toString()} ${time?.toString()}`;

  const dateTimeMoment = moment(combinedDateTimeString, "YYYY/MM/DD HH:mm:ss");

  const formattedDateTime = dateTimeMoment.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  return formattedDateTime;
};

export const getRouterUrl = (startRouters: RouterKakao[], endRouters: RouterKakao[]) => {
  const hasStartAddress = startRouters.some((router) => router.fullAddress);
  const hasEndAddress = endRouters.some((router) => router.fullAddress);
  if (!hasStartAddress || !hasEndAddress) {
    return "";
  }
  const baseUrl = "https://map.kakao.com/?target=car";
  let routeCoordinates = "";
  let routeNames = "";
  const processRouter = (routers: RouterKakao[], indexOffset: number) => {
    for (let i = 0; i < routers.length; i++) {
      const router = routers[i];
      if (router.fullAddress) {
        routeCoordinates += `${router.coorX},${router.coorY},`;
        routeNames += `rt${indexOffset + i + 1}=${encodeURIComponent(router.fullAddress)}&`;
      }
    }
  };
  processRouter(startRouters, 0);
  processRouter(endRouters.slice(1), startRouters.length);
  if (endRouters[0].fullAddress) {
    routeCoordinates += `${endRouters[0].coorX},${endRouters[0].coorY}&`;
    routeNames += `rt${startRouters.length + endRouters.length}=${encodeURIComponent(endRouters[0].fullAddress)}`;
  }
  return `${baseUrl}&rt=${routeCoordinates}${routeNames}`;
};

export function swapElementsBetweenArrays<T extends any>(arr1: T[], arr2: T[], index: number) {
  // Kiểm tra xem index có hợp lệ không
  if (index >= 0 && index < arr1.length && index < arr2.length) {
    // Sử dụng một biến tạm thời để lưu trữ giá trị của arr1[index]
    var temp = arr1[index];

    // Gán giá trị của arr1[index] bằng giá trị của arr2[index]
    arr1[index] = arr2[index];

    // Gán giá trị của arr2[index] bằng giá trị tạm thời
    arr2[index] = temp;
  }
  const newArr1 = clone(arr1);
  const newArr2 = clone(arr2);
  return [newArr1, newArr2];
}

/* ------------------------------ 거리 기준으로 금액 계산 ----------------------------- */
export const getMoneyRegardingDistance = (distance: number) => {
  const thresholds = [10, 20, 30];
  const rewards = [30000, 50000, 70000];
  const distanceMetter = distance / 1000;

  for (let i = 0; i < thresholds.length; i++) {
    if (distanceMetter < thresholds[i]) {
      return rewards[i];
    }
  }
  if (distanceMetter > 30) {
    return 70000;
  }
  return 0;
};
